.payslip-authority[data-v-49252cbc] {
  min-width: 785px;
  display: flex;
  flex-direction: column;
}
.payslip-authority .title[data-v-49252cbc] {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}
.payslip-authority .search-box[data-v-49252cbc] {
    padding: 30px 0 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.payslip-authority .payslip-authority-content[data-v-49252cbc] {
    flex: 1;
}
